import { Routes } from '@angular/router';

// Public routes
export const PUBLIC_ROUTES: Routes = [
  {
    path: "",
    pathMatch: "full",
    loadChildren: "./modules/home/home.module#HomeModule",
  },
  {
    path: "proyecto/:slug",
    loadChildren: "./modules/project/project.module#ProjectModule",
  },
  {
    path: "inversores",
    loadChildren: "./modules/investors/investors.module#InvestorsModule",
  },
  {
    path: "prensa",
    loadChildren: "./modules/news/news.module#NewsModule",
  },
];
