import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter, take } from 'rxjs/operators';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router) { }

  navigateToDevsSection() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/') {
        const section = document.getElementById('devs');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
    this.router.navigate(['/']);
  }

  navigateToAboutUsSection() {
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      take(1)
    ).subscribe((event: NavigationEnd) => {
      if (event.url === '/') {
        const section = document.getElementById('about-us');
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

}
