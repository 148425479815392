import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from './core/components/layouts/public/public.component';
import { PUBLIC_ROUTES } from './shared/routes/public.routes';

const routes: Routes = [
  { path: '', component: PublicLayoutComponent, children: PUBLIC_ROUTES },
  { path: '**', redirectTo: '' }
];
@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
